import React from 'react';
import Uppy from '@uppy/core';
import GoogleDrive from '@uppy/google-drive';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import AwsS3 from '@uppy/aws-s3';

const uppy = new Uppy({
  debug: true,
  autoProceed: true,
  restrictions: {
    allowedFileTypes: ['video/*']
  }
})
uppy.use(XHRUpload, { limit: 1 })
uppy.use(GoogleDrive, { companionUrl: "https://www.lanvaserver.com:3020" });
uppy.use(AwsS3, {
  shouldUseMultipart: (file) => file.size > 100 * 2 ** 20,
  companionUrl: "https://www.lanvaserver.com:3020"
})

const App = () => {
  return (
    <div>
      <Dashboard uppy={uppy} plugins={['GoogleDrive']} allowMultipleUploads={false} showProgressDetails={true} />
    </div>
  )
}

export default App;